import { GlobalSettingsEnum, ServiceUnifiedNameEnum } from '@/enums'

export const PLACEHOLDER_AFFIX = '%'
export const config = {
  pharmaservices: {
    primary: '#00A84E',
    secondary: '#4ac380',
    accent: '#badfbf',
    error: '#cc0a00',
    info: '#badfbf',
    success: '#006830',
    warning: '#ff8500',
    ga4: 'G-F5V68LLK4T',
    GTM: 'GTM-M78T652'
  },
  uniprix: {
    primary: '#0c4da2',
    secondary: '#ff8500',
    accent: '#7396c4',
    error: '#cc0a00',
    info: '#eaf4ff',
    success: '#4ea835',
    warning: '#ff8500',
    ga4: 'G-F5V68LLK4T',
    GTM: 'GTM-M78T652'
  },
  pharmaprix: {
    primary: '#cf342f',
    secondary: '#45add6',
    accent: '#cf6666',
    error: '#cc0a00',
    info: '#45add6',
    success: '#cc0a00',
    warning: '#ff8500',
    ga4: 'G-F5V68LLK4T',
    GTM: 'GTM-M78T652'
  },
  clicsante: {
    primary: '#003E6C',
    secondary: '#00D2E6',
    accent: '#6fa7fc',
    error: '#cc0a00',
    info: '#eaf4ff',
    success: '#4caf50',
    warning: '#ff8500',
    ga4: 'G-F5V68LLK4T',
    GTM: 'GTM-M78T652'
  },
  placeHolders: [
    {
      getter: 'globalSettings',
      affix: PLACEHOLDER_AFFIX,
      settings: [
        {
          id: GlobalSettingsEnum.MIN_AGE_TO_BOOK_BY_SERVICE_UNIFIED,
          type: 'json',
          key: 'MIN_AGE_FOR_ZONA_BOOKING',
          value: ServiceUnifiedNameEnum.SHINGLES_VACCINE
        },
        {
          id: GlobalSettingsEnum.MIN_AGE_TO_BOOK_BY_SERVICE_UNIFIED,
          type: 'json',
          key: 'MIN_AGE_FOR_ZONA_BOOKING_MINUS_ONE',
          value: ServiceUnifiedNameEnum.SHINGLES_VACCINE,
          adjustValue: (value) => {
            return +value - 1
          }
        }
      ]
    }
  ]
}
