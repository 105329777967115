export function setProduct(domain) {
  let product = 'clicsante'
  if (
    import.meta.env.VITE_APP_ENV === 'development' &&
    import.meta.env.VITE_APP_PRODUCT !== 'auto-detect'
  ) {
    product = import.meta.env.VITE_APP_PRODUCT
  } else if (domain.search('pharmaprix') !== -1) {
    product = 'pharmaprix'
  } else if (domain.search('uniprix') !== -1) {
    product = 'uniprix'
  } else if (domain.search('pharmaservices') !== -1) {
    product = 'pharmaservices'
  }
  if (product !== 'clicsante') {
    window.location = 'https://portal3.clicsante.ca'
  }

  return product
}

export function setFavicon(product) {
  /* show the right favicon for product */
  const searchString = 'favicon-' + product
  const x = document.querySelectorAll('link')
  for (let i = 0; i < x.length; i++) {
    const str = x[i].href
    const str2 = x[i].rel
    if (str.search(searchString) === -1 && str2 !== 'stylesheet') {
      x[i].remove()
    }
  }
}
